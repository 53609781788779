<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="代理商管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="orgName">
					<el-input v-model.trim="searchForm.merchantName" placeholder="请输入代理商名" />
				</el-form-item>
				<!-- <el-form-item>
					<el-input v-model.trim="searchForm.orgName1" placeholder="选择订单名" />
					<el-cascader v-model="searchForm.areaIds" style="width: 350px;" :options="orgAreaTree"
						placeholder="选择订单名" clearable filterable collapse-tags
						:props="{ multiple: true, checkStrictly: false, emitPath: false, label: 'orgName', value: 'orgId' }" />
				</el-form-item> -->

				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="代理商列表">
			<!-- <template slot="button">
				<el-button type="success" @click="addGarden">添加代理商</el-button>
			</template> -->
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="appid" label="id" min-width="160" /> -->
				<el-table-column prop="merchantName" label="代理商名" />
				<el-table-column prop="mchNo" label="商户号" />
				<el-table-column prop="open" label="有效" />
				<!-- <el-table-column label="有效">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.isOpen" active-color="#aaaaaa" inactive-color="#33ccff"
							:active-value="0" :inactive-value="1"
							@change="changeStatus($event,scope.row,scope.$index)" />
					</template>
				</el-table-column> -->


				<!-- <el-table-column prop="birthday" label="生日" min-width="100" /> -->
				<!-- <el-table-column prop="principalName" label="下单数" min-width="100" />
				<el-table-column prop="phone" label="支付数" min-width="100" />
				<el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip /> -->
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">查看客户表</el-button>
						<!-- <el-button type="text" class="textOrange" @click="deleteGarden(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>
		<GDialog :dialog.sync="gardenDialog" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<el-table :data="tableData1" stripe style="width: 100%">
				<el-table-column prop="id" label="id" min-width="160" />
				<el-table-column prop="realName" label="客户名" min-width="100" />
				<el-table-column prop="mobilePhone" label="电话号码" min-width="100" />
				<el-table-column prop="birthday" label="生日" min-width="100" />
				<el-table-column width="120" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden1(scope.row)">查看用户表</el-button>
						<!-- <el-button type="text" class="textOrange" @click="deleteGarden(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total2 > 0" ref="ref_GPagination" :total="total2" :page.sync="searchForm2.page"
				:size.sync="searchForm2.size" @pagination="fetchData2" />
		</GDialog>

		<GDialog :dialog.sync="gardenDialog2" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<el-table :data="tableData2" stripe style="width: 100%">
				<el-table-column prop="id" label="id" min-width="160" />
				<el-table-column prop="realName" label="用户名" min-width="100" />
				<el-table-column prop="mobilePhone" label="电话号码" min-width="100" />
				<el-table-column prop="birthday" label="生日" min-width="100" />
			</el-table>
			<GPagination v-show="total3 > 0" ref="ref_GPagination" :total="total3" :page.sync="searchForm3.page"
				:size.sync="searchForm3.size" @pagination="fetchData3" />
		</GDialog>

		<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="代理商名" prop="merchantName">
					<el-input v-model="themeForm.merchantName" maxlength="35" placeholder="请输入代理商名" />
				</el-form-item>
				<el-form-item label="商户名" prop="mchId">
					<el-input v-model="themeForm.mchId" maxlength="35" placeholder="请输入商户名" />
				</el-form-item>
				<!-- <el-form-item label="备注" prop="remark">
		  <el-input v-model="themeForm.remark" maxlength="200" show-word-limit type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入备注" />
		</el-form-item> -->
			</el-form>
		</GDialog>
	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		findMfAgent
	} from '@api/agentManagement/api_agentManagement'
	import {
		postFindMfDockor,
		findMfDockorAllUser
	} from '@api/gardenManagement/api_gardenManagement'
	export default {
		data() {
			return {
				currentRow: {},
				isOpen: true,
				gardenDialog: {
					title: '客户表',
					visible: false
				},
				gardenDialog2: {
					title: '用户表',
					visible: false
				},
				themeDialog: {
					title: '标题哦',
					visible: false
				},
				tableData: [],
				tableData1: [],
				tableData2: [],
				total: 0,
				total2: 0,
				total3: 0,
				searchForm: {
					// merchantId: 1,
					// keyWord: "",
					// mobilePhone: "",
					// realName: "",
					merchantName: "",
					page: 1,
					size: 20,
					startNum: 1
				},
				searchForm2: {
					page: 1,
					size: 10,
				},
				searchForm3: {
					page: 1,
					size: 10,
				},
				themeForm: {
					merchantName: '',
					mchId: '',
				},
				gardenForm: {
					areaId: '',
					orgName: '',
					principalName: '',
					phone: '',
					remark: ''
				},


				rules: {
					merchantName: [{
						required: true,
						message: '请输入用户名称',
						trigger: 'blur'
					}],
					mchId: [{
							required: true,
							message: '请输入用户性别',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],

				}
			}
		},
		computed: {
			...mapState('commonModule', ['orgAreaTree'])
		},
		created() {
			// this.getOptions()
			this.fetchData()
		},

		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			// getOptions() {
			// 	// 查询组织机构树-到片区；2到片区，3到园所
			// 	this.$store.dispatch('commonModule/getOrgTree', 2)
			// },
			fetchData() {
				this.searchForm.type = 3
				findMfAgent(this.searchForm).then(res => {
					this.tableData = res.records
					this.total = res.total
				})
			},
			fetchData2() {
				
				postFindMfDockor(this.searchForm2).then(res => {
					this.tableData1 = res.records
					this.total2 = res.total
				})
			},
			fetchData3() {
				
				findMfDockorAllUser(this.searchForm3).then(res => {
					this.tableData2 = res.records
					this.total2 = res.total
				})
			},
			// changeStatus(row){
			// 	console.log(row,1111)
			// },
			addGarden() {
				this.themeDialog.visible = true
				this.themeDialog.title = '添加代理商'
			},
			editGarden(row) {
				console.log(row, 1111)
				this.gardenDialog.visible = true
				let params = {
					merchantId: row.merchantId,
					page: 1,
					size: 10

				}
				postFindMfDockor(params).then(res => {
					this.tableData1 = res.records
					this.total2 = res.total
				})
			},
			resetThemeForm(formName) {
				this.$refs[formName].resetFields()
				this.themeForm.themeName = ''
				this.themeDialog.visible = false
			},
			editGarden1(row) {
				console.log(row, 1111)
				this.gardenDialog2.visible = true
				let params = {
					parentId: row.userId,
					page: 1,
					size: 10

				}
				findMfDockorAllUser(params).then(res => {
					this.tableData2 = res.records
					this.total3 = res.total
				})
			},
			deleteGarden(row) {
				this.$confirm('是否确认删除?', '提示', {
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					this.currentRow = row
					delOrg({
						orgId: row.id
					}).then(res => {
						this.$message.success('删除成功！')
						if (this.total % this.searchForm.size === 1) {
							this.searchForm.page = this.searchForm.page - 1 === 0 ? 1 : this.searchForm
								.page - 1
						}
						this.fetchData()
					})
				})
			},

			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (!valid) {
			// 			return false
			// 		}
			// 		const params = {
			// 			...this.gardenForm
			// 		}
			// 		let _api
			// 		if (this.gardenDialog.title === '添加园所') {
			// 			_api = addOrg
			// 		} else {
			// 			_api = updateOrg
			// 			params.orgId = this.currentRow.id
			// 		}
			// 		_api(params).then(res => {
			// 			this.$message.success(this.gardenDialog.title + '成功！')
			// 			this.resetGardenForm('ref_gardenForm')
			// 			this.fetchData()
			// 		})
			// 	})
			// },
			submitThemeForm() {
				this.gardenDialog.visible = false
			},
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog.visible = false
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}
	
	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}
	
	.reviewed {
		color: #38b9be;
	}
</style>
