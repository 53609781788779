import request from '@utils/fetch'

//查看代理商列表
export function findMfAgent(data) {
  return request({
    url: '/mf/findMfAgent',
    method: 'post',
    data
  })
}

